import { useStorage } from '@vueuse/core';

export const useProgressStorage = () => {
    const progress = useStorage('progress', {
        modules: []
    });

    function initModule(slug, id, title, chapters) {
        if (progress.value.modules.find(module => module.id === id)) { 
            return;
        }

        progress.value.modules.push({
            slug,
            id,
            title,
            chapters
        });
    }

    function completeChapter(moduleId, chapterId) {
        const module = progress.value.modules.find(entry => entry.id === moduleId);
        if (!module) {
            return;
        }

        const chapter = module.chapters.find(entry => entry.id === chapterId);
        if (!chapter) {
            return;
        }

        chapter.completed = true;
    }

    function completeChapterItem(moduleSlug, chapterSlug, itemSlug) {
        const module = progress.value.modules.find(entry => entry.slug === moduleSlug);

        if (!module) {
            return;
        }

        const chapter = module.chapters.find(entry => entry.slug === chapterSlug);
        if (!chapter) {
            return;
        }

        const item = chapter.sections.find(entry => entry.slug === itemSlug);

        if (!item) {
            return;
        }

        item.completed = true;
    }

    function clearProgress() {
        progress.value.modules = [];
    }

    function completeChapterSlugs(moduleSlug) {
        // returns chapters where all items are marked as completed:
        const module = progress.value.modules.find(entry => entry.slug === moduleSlug);

        if (!module) {
            return [];
        }

        return module.chapters.reduce((acc, chapter) => {
            if (chapter.sections.every(item => item.completed)) {
                acc.push(chapter.slug);
            }
            return acc;
        }, []);
    }

    const moduleProgress = (moduleSlug) => {
        const module = progress.value.modules.find(entry => entry.slug === moduleSlug);

        if (!module) {
            return 0;
        }

        const totalChapterItems = module.chapters.reduce((acc, chapter) => acc + chapter.sections.length, 0);
        const completedChapterItems = module.chapters.reduce((acc, chapter) => {
            return acc + chapter.sections.filter(item => item.completed).length;
        }, 0);

        return Math.round((completedChapterItems / totalChapterItems) * 100);
    };

    const currentChapter = computed(() => {
        // returns the first chapter that has incomplete items
        return progress.value.modules.reduce((acc, module) => {
            if (acc) {
                return acc;
            }

            return module.chapters.find(chapter => {
                return chapter.sections.some(item => !item.completed);
            });
        }, null);
    });

    const currentChapterItem = computed(() => {
        // returns the first item that is not completed in the current chapter
        const chapter = currentChapter.value;

        if (!chapter) {
            return null;
        }

        return chapter.sections.find(item => !item.completed);
    });

    const getModuleTitle = (slug) => {
        const module = progress.value.modules.find(entry => entry.slug === slug);

        if (!module) {
            return '';
        }

        return module.title;
    };

    return { progress, initModule, clearProgress, completeChapter, currentChapter, currentChapterItem, completeChapterSlugs, completeChapterItem, moduleProgress, getModuleTitle };
};